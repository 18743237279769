<template>
  <el-dialog
    v-dialog-drag
    :title="title"
    :modal="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :visible.sync="showFileDialog"
    width="70%"
  >
    <iframe
      ref="fileIframe"
      :src="link"
      frameborder="0"
      style="width: 100%; height: 500px"
    ></iframe>
    <span slot="footer">
      <el-button size="small" @click="download">下载</el-button>
    </span>
    <span slot="footer">
      <el-button size="small" @click="showFileDialog = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "图片预览",
    },
    fileUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showFileDialog: false,
      link: "",
      fileRow:{}
    };
  },
  methods: {
    jobTicketRow(row){
      this.fileRow = row
      this.showFile(this.fileRow.url)
    },
    download(){
      const fileUrl = this.fileRow.url;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.download = name;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    close() {
      this.showFileDialog = false;
    },
    showFile(url) {
      this.link =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(url));
      this.showFileDialog = true;
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
  },
};
</script>

<style>
</style>
