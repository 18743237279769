<template>
  <div>
    <!-- <head-layout
      head-title="人员签字"
    ></head-layout> -->
    <el-form :model="securityData" label-width="100px" style="padding: 12px">
      <el-row>
        <el-col :span="24">
         <el-form-item label="交底人签名:" prop="receive">
            <div style="display: flex;flex-wrap: wrap">
              <div class="imgBox" @mouseenter="item.maskShow = true" @mouseleave="item.maskShow = false"
                   v-for="(item,index) in securityData.receive" :key="index" style="margin:0 12px 12px 0;">
                <img :src="item.imgUrl">
                <div class="maskBox" v-if="item.maskShow">
                  <i class="el-icon-view" @click="viewImg(item.imgUrl)"></i>
                  <i class="el-icon-delete" @click="deleteImg(item.id,'receive',index)"
                     v-if="!doned && ['edit', 'add','sign','flow'].includes(securityType)&&['DISCLOSED'].includes(businessStatus)"></i>
                </div>
              </div>
              <div class="cardBorder" @click="openSignature('receive')"
                   v-if="!doned && ['edit', 'add','sign','flow'].includes(securityType)&&['DISCLOSED'].includes(businessStatus)">
                <i class="el-icon-plus"></i>
              </div>
            </div>
         </el-form-item>
        </el-col>
       <el-col :span="24" v-if="signTypeShow">
         <el-form-item label="被交底人签名:" prop="signType">
          <div style="display: flex;flex-wrap: wrap">
            <div class="imgBox" @mouseenter="item.maskShow = true" @mouseleave="item.maskShow = false"
           v-for="(item,index) in securityData.signType" :key="index" style="margin:0 12px 12px 0;">
             <img :src="item.imgUrl">
             <div class="maskBox" v-if="item.maskShow">
               <i class="el-icon-view" @click="viewImg(item.imgUrl)"></i>
               <i class="el-icon-delete" @click="deleteImg(item.id,'signType',index)"
                  v-if="['edit', 'add','sign','workflow','flow'].includes(securityType)&&['DISCLOSED'].includes(businessStatus)"></i>
             </div>
           </div>
           <div class="cardBorder" @click="openSignature('signType')"
                v-if="['edit', 'add','sign','workflow','flow'].includes(securityType) &&['DISCLOSED'].includes(businessStatus)">
             <i class="el-icon-plus"></i>
           </div>
          </div>
         </el-form-item>
       </el-col>

      </el-row>
    </el-form>
    <!--签名弹窗-->
    <el-dialog
      title="电子签名"
      :visible.sync="signatureShow"
      v-if="signatureShow"
      width="1000px">
      <signature @getSignatureImg="getSignatureImg"></signature>
    </el-dialog>
    <el-dialog
      title="预览签名"
      :visible.sync="imgShow"
      width="1000px">
      <img :src="imgSrc" style="width: 100%;height: 500px;">
    </el-dialog>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import signature from "@/views/components/signature";
import {removeWtDisclosure} from "@/api/workTicket";

export default {
  props: {
    securityType: {
      type: String,
      default: "view",
    },
    doned:{
      type: Boolean,
      default: false
    },
    signTypeShow:{
      type: Boolean,
      default: true
    },
    businessStatus: {
      type: String,
      default: "view",
    },
  },
  data() {
    return {
      securityData: {
        signType:[],
        receive: []
      },
      signatureShow: false,
      signatureType: '',
      imgShow: false,
      imgSrc: '',
      maskShow: false
    };
  },
  components: {
    HeadLayout,
    signature
  },
  methods: {
    getSignatureImg(imgUrl) {
      if (this.signatureType == 'signType') {
        this.securityData.signType.push({
          imgUrl: imgUrl,
          maskShow: false
        });
      } else {
        this.securityData.receive.push({
          imgUrl: imgUrl,
          maskShow: false
        })
      }
      this.signatureShow = false;
    },
    openSignature(type) {
      this.signatureShow = true;
      this.signatureType = type;
    },
    deleteImg(id, type, index) {
      if (id && id > 0) {
        removeWtDisclosure(id).then((res) => {
          if (type == 'signType') {
            this.securityData.signType.splice(index, 1)
          } else {
            this.securityData.receive.splice(index, 1)
          }
          this.$message({
            type: "success",
            message: res.data.msg,
            duration: 1000,
          });
        });
      } else {
        if (type == 'signType') {
          this.securityData.signType.splice(index, 1)
        } else {
          this.securityData.receive.splice(index, 1)
        }
        this.$message({
          type: "success",
          message: "操作成功",
          duration: 1000,
        });
      }
    },
    viewImg(imgUrl) {
      this.imgShow = true;
      this.imgSrc = imgUrl;
    },
    submit() {
      return this.securityData
    },
    initData(data) {
      if (data == undefined) return
      var filter = data.filter(e => e.signType == "2");
      var receive = data.filter(e => e.userType == "receive");
      this.securityData.receive = []
      this.securityData.signType = []
      filter.forEach(e => {
        this.securityData.signType.push(
          {
            imgUrl: e.userSign,
            id: e.id,
            maskShow: false
          }
        )
      })
      receive.forEach(e => {
        this.securityData.receive.push(
          {
            imgUrl: e.userSign,
            id: e.id,
            maskShow: false
          }
        )
      })
    },
  },
};
</script>

<style scoped lang='scss'>
.cardBorder {
  width: 170px;
  height: 170px;
  border: 1px dashed #DDD;
  border-radius: 10px;
  cursor: pointer;
  line-height: 170px;
  text-align: center;
  color: #8c939d;
  font-size: 28px;
}

.cardBorder:hover {
  border: 1px dashed #2979FF;
}

.imgBox {
  width: 170px;
  height: 170px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.maskBox {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  font-size: 22px;
}

.maskBox i {
  line-height: 170px;
  width: 30px;
}

.maskBox i:hover {
  color: #2979FF;
}

.imgBox img {
  width: 100%;
  height: 100%;
}
</style>
