import {
  getWtTicketDetail,
  myWtTicketSubmit,
  submitWtTicket,
  submitSecurityDisclosure,
  submitSafetySupervision,
  submitAcceptanceConfirmation,
  notSubmittedForSignature,
  notSubmittedForSignatureSubmit
} from "@/api/workTicket";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {detail as processDetail} from "@/api/plugin/workflow/process";
import {dictionaryBiz} from "@/api/reportTasks";

export default {
  mixins: [exForm],
  data() {
    return {
      work_permit_type:[],
      nextTasks:[],
      ticketDetail: {},
      examineForm: {},
      formProcess: {
        id: '',
        processId: '',
        data: {},
        // userDialogSkip: false
      },
    }
  },
  computed:{
    ticketStep() {
      let number = -1;
      if (
        ["PREPARE", "CONFIRMING", "CONFIRMING"].includes(this.businessStatus)
      ) {
        number = 0;
      }
      if (["DISCLOSED"].includes(this.businessStatus)) {
        number = 1;
      }
      if (["SUPERVISE"].includes(this.businessStatus)) {
        number = 2;
      }
      if (["CHECKACCEPT"].includes(this.businessStatus)) {
        number = 3;
      }
      if (["FINISHED"].includes(this.businessStatus)) {
        number = 4;
      }
      return number;
    },
  },
  mounted() {
    this.getDict()
  },
  methods: {
    // 提交
    async headRefer() {
      await this.headSave("processSubmission").then((saveData) => {
        if (saveData) {
          if (this.businessStatus == "PREPARE") {
            getWtTicketDetail(this.dataForm.ticketCode).then((res) => {
              this.ticketDetail = res.data.data
              if (this.ticketDetail.processInstanceId) {
                this.$loading();
                processDetail({processInsId: this.ticketDetail.processInstanceId}).then((res) => {
                  this.reportExResubmit(res.data.data.process).then(res => {
                    // 关闭当前页面
                    this.closeTag()
                  });
                });
                // });
              } else {
                notSubmittedForSignature(this.ticketDetail.ticketCode).then((res) => {
                  console.log("this.businessStatus",this.businessStatus)
                  let userText = "";
                  if (res.data.data !== "complete") {
                    userText = res.data.data.toString() + "没有签署";
                  } else {
                    userText = "";
                  }
                  if (userText!="") {
                    this.$confirm(userText + " 是否要提交", {
                      confirmButtonText: "确认",
                      cancelButtonText: "取消",
                      type: "warning",
                    }).then(() => {
                      if (userText !== "") {
                        notSubmittedForSignatureSubmit(this.ticketDetail.ticketCode);
                      }
                      this.selectUser(this.ticketDetail, false)
                    });
                  } else {
                    this.selectUser(this.ticketDetail, false)
                  }
                });
              }
            })
          }
          this.saveData()
        }
      });
    },
    saveData(){
      if (this.businessStatus == "CONFIRMING") {
        this.headSave("submit");
      }
      if (this.businessStatus == "DISCLOSED") {
        submitSafetySupervision(this.dataForm.ticketCode).then((res) => {
          this.$message.success('提交成功');
          this.headCancel();
        });
      }
      if (this.businessStatus == "SUPERVISE") {
        submitSecurityDisclosure(this.dataForm.ticketCode).then((res) => {
          this.$message.success('提交成功');
          this.headCancel();
        });
      }
      if (this.businessStatus == "CHECKACCEPT") {
        submitAcceptanceConfirmation(this.dataForm.ticketCode).then((res) => {
          this.$message.success('提交成功');
          this.headCancel();
        });
      }
    },
    async getDict(){
      this.work_permit_type=await dictionaryBiz("work_permit_type").then((res) => res.data.data);
    },
    closeTag(){
      this.$loading().close();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
      this.$message.success('提交成功');
    },
    selectUser(row, flag) {
      //修改草稿状态为待审批
      this.$loading();
      let data = {
        id: row.id,
        businessStatus: "APPROVING"
      }
      if (this.ticketDetail.processInstanceId) {
        myWtTicketSubmit(data).then(res => {
          // 关闭当前页面
          this.closeTag()
        })
      } else {
        this.formProcess.id = row.id
        this.formProcess.data = {...row}
        this.formProcess.deptId = this.userInfo.dept_id
        this.formProcess.processBusinessName = row.content
        this.formProcess.processDefKey = 'TicketV3'
        this.formProcess.weChatUrl = '/pages/news/jobticket2/ticketFlowWebview'
        this.formProcess.formUrl = '/jobslip/jobTicketList/jobTicketApproving';
        this.formProcess.tokenUrl = '/api/sinoma-hse-prj/wtTicket/approve';
          let filter = this.work_permit_type.filter(e => e.dictKey == row.ticketType);
          this.formProcess.taskName = filter[0].dictValue + "流程|" + row.content + "|" + row.ticketCode + "|" + this.userInfo.nick_name
          this.formProcess.typeName = '危险作业';
          // this.formProcess.typeName = this.$route.query.jobTicketType;
          //发起流程
          this.handleStartProcess6(true, flag).then(done => {
            // 更新业务数据流程实例id
            data.processInstanceId = done.data.data
            myWtTicketSubmit(data).then(res => {
            this.closeTag()
            })
          })

      }
    },
    closeProcess() {
      this.$loading().close();
    },
    // 选择流程用户
    handleUserSelection(userIds) {
      this.formProcess.assignee =userIds;
      this.selectUser(this.ticketDetail, false)
    },
  }
}
